
import { Options, Vue } from "vue-class-component";
import Header from "@/views/header.vue";
import Nav from "@/views/nav.vue"; // @ is an alias to /src
import Banner from "@/views/banner.vue"; // @ is an alias to /src
import Page1 from "@/views/page1.vue"; // @ is an alias to /src
import Page2 from "@/views/page2.vue"; // @ is an alias to /src
import Page3 from "@/views/page3.vue"; // @ is an alias to /src
import Page4 from "@/views/page4.vue"; // @ is an alias to /src
import Page5 from "@/views/page5.vue"; // @ is an alias to /src
import Page6 from "@/views/page6.vue"; // @ is an alias to /src
import Page7 from "@/views/page7.vue"; // @ is an alias to /src
import Page8 from "@/views/page8.vue"; // @ is an alias to /src
import Footer from "@/views/footer.vue"; // @ is an alias to /src
import axios from "axios";
import { getArticleList, getArticleInfo } from "@/api/index";

@Options({
  components: {
    Header,
    Nav,
    Banner,
    Page1,
    Page2,
    Page3,
    Page4,
    Page5,
    Page6,
    Page7,
    Page8,
    Footer,
  },
})
export default class Home extends Vue {
  ondhl(isvalue: any, id: any) {
    this.$router.push({
      path: "/",
      query: {
        index: id,
      },
    });
  }

  private detail: any = {};
  private async getListId(id: string, i: number) {
    window.scrollTo({ top: 0 });
    this.activeIndex = i;

    let { data }: any = await getArticleInfo(id);
    this.detail = data;
  }

  private dataList: any = "";
  private activeIndex = 0;

  private async getList() {
    let { data }: any = await getArticleList();
    if (data && data.length) {
      this.dataList = data;
      this.dataList.forEach((t: any, i: number) => {
        if (t.id == this.$route.query.id) {
          // this.detail = t;
          this.getListId(t.id, i);
        }
      });
    }
  }

  mounted() {
    this.getList();
  }
}
